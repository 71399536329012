export default {

  successState: 200,

  // requestTimeout: 10000,
  requestTimeout: 100000,

  tokenKey: 'token',

  userKey: 'user',

  tokenHeaderName: 'Authorization',

  tokenTypeField: 'token_type',

  tokenValueField: 'access_token',

  tokenRefField: 'refresh_token',

  tokenExpiresField: 'expires_in',

  roleField: 'Roles',

  keyField: 'ID',

  nameField: 'UserName',

  avatarField: 'HeadImageUrl',

  authorField: 'Policies',

  pageFieldName: 'Page',

  totalField: 'TotalCount',

  sizeFieldName: 'PerPage',

  defaultPageSize: 10,

  uploadUrl: '/api/upload/upload',

  imageTypes: ['image/jpeg', 'image/png'],

  imageMaxSize: 10240, // kb

  fileMaxSize: 512000, // kb

  lang: false,

  flow: false,

  baiduMapAk: 'PqXv7L2PZkDde0DQNT8AuQHwNyhvuOCQ'
}
