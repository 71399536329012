import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/layout/home'
  },
  {
    path: '/layout',
    component: () => import('@/views/layout.vue'),
    children: [
      {
        path: 'home',
        component: () => import('@/views/home.vue')
      },
      {
        path: 'about',
        component: () => import('@/views/about.vue')
      },
      {
        path: 'product/category',
        component: () => import('@/views/productCategory.vue')
      },
      {
        path: 'product',
        component: () => import('@/views/product.vue')
      },
      {
        path: 'product/detail/:id',
        component: () => import('@/views/productDetail.vue')
      },
      {
        path: 'solution',
        component: () => import('@/views/solution.vue')
      },
      {
        path: 'solution/detail/:id',
        component: () => import('@/views/solutionDetail.vue')
      },
      {
        path: 'case',
        component: () => import('@/views/case.vue')
      },
      {
        path: 'case/detail/:id',
        component: () => import('@/views/caseDetail.vue')
      },
      {
        path: 'new',
        component: () => import('@/views/new.vue')
      },
      {
        path: 'new/detail/:id',
        component: () => import('@/views/newDetail.vue')
      },
      {
        path: 'contact',
        component: () => import('@/views/contact.vue')
      },
      {
        path: 'search',
        component: () => import('@/views/search.vue')
      },
    ]
  },
]

const router = new VueRouter({
  // mode: "history",
  scrollBehavior(to, from, savedPosition) {
    let flag = false;
    let whiteList = ["/layout/about", "/layout/product/detail", "/layout/solution/detail/"]
    whiteList.forEach(item => {
      if (to.path.includes(item)) {
        flag = true;
      }
    })

    if (flag) {
      return { y: 0 }
    } else if (savedPosition) {
      return savedPosition
    }
  },
  routes,
})

export default router
