import { token, getInfo, refreshToken } from '@/api/user'
import { getToken, setToken, removeToken, getUser, setUser, removeUser } from '@/utils/auth'
import defaultSettings from '@/utils/settings'
// import { actions } from '@/permission'

const { tokenRefField } = defaultSettings

const state = {
  token: getToken(),
  user: getUser()
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },

  SET_USER: (state, user) => {
    state.user = user
  }
}

const actions = {
  // user login
  token({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      token(userInfo)
        .then(data => {
          // const { data } = response
          // commit('SET_TOKEN', data)
          // setToken(data.token)
          setToken(data.Data)
          commit('SET_TOKEN', data.Data)
          setTimeout(() => {
            resolve()
          }, 1000)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  refreshToken({ commit }, refresh_token) {
    console.log("refreshToken");
    return new Promise((resolve, reject) => {
      var args = {}
      args[tokenRefField] = refresh_token
      refreshToken(args)
        .then(data => {
          // console.log('refreshToken-success', data)
          setToken(data.Data)
          commit('SET_TOKEN', data.Data)
          resolve()
        })
        .catch(error => {
          // console.log('refreshToken-faild', refresh_token, error)
          reject(error)
        })
        .finally(() => {
          // console.log('refreshToken-end', refresh_token)
        })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then(data => {
          setUser(data.Data)
          commit('SET_USER', data.Data)
          resolve(data.Data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    commit('SET_TOKEN', null)
    commit('SET_USER', null)
    removeToken()
    removeUser()
    // actions.routeClear()
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      // commit('SET_TOKEN', '')
      // commit('SET_ROLES', [])
      removeToken()
      removeUser()
      resolve()
    })
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
