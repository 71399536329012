// import variables from '@/styles/element-variables.scss'
import defaultSettings from '@/utils/settings'

const { showSettings, tagsView, fixedHeader, sidebarLogo } = defaultSettings

const state = {
  // theme: variables.theme,
  showSettings: showSettings,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  lang: localStorage.getItem('lang') || 'zh_cn'
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  },
  SET_LANG: (state, lang) => {
    state.lang = lang
    localStorage.setItem('lang', lang)
  }
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  },
  setLang({ commit }, lang) {
    commit('SET_LANG', lang)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
