import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
// import { getToken, setToken } from '@/utils/auth'
import qs from 'qs'
import defaultSettings from '@/utils/settings'

const {
  dataFieldName,
  codeFieldName,
  pageFieldName,
  sizeFieldName,
  // successState,
  tokenHeaderName,
  requestTimeout,
  tokenTypeField,
  tokenValueField,
  tokenRefField
  // tokenExpiresField
} = defaultSettings

// 是否正在刷新的标记
let isRefreshing = false
// 重试队列，每一项将是一个待执行的函数形式
let retryRequests = []

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // headers:{
  //   "mockUrl":process.env.VUE_APP_BASE_API_MOCK,
  // },
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: requestTimeout // request timeout
})
// mockUrl
// service.defaults.headers.common['mockUrl'] = '/dev-api'

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (config.url.indexOf('vue-element-admin') > -1) {
      config.baseURL = `${process.env.VUE_APP_MOCK_API}`
    }
    // delete config.headers.common['mockUrl']
    if (window.baseUrl && window.baseUrl !== '') config.baseURL = window.baseUrl
    if (store.getters.token && (config.isAnonymous === undefined || config.isAnonymous === false)) {
      const tokenObj = store.getters.token
      config.headers[tokenHeaderName] = tokenObj[tokenTypeField] + ' ' + tokenObj[tokenValueField]
    }
    if (config.params) {
      config.params = Object.assign(config.params, { lang: store.getters.lang || 'zh-cn' })
      config.paramsSerializer = function (params) {
        var keys = Object.keys(params)
        for (let index = 0; index < keys.length; index++) {
          const element = params[keys[index]]
          if (element === null || element === undefined || element === '') {
            delete params[keys[index]]
          }
        }
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    } else {
      config.params = { lang: store.getters.lang || 'zh-cn' }
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */
  response => {
    const res = response
    // console.log(res)
    const error = res.data.Error || res.data

    let state = res.status
    if (codeFieldName && res.data[codeFieldName] !== undefined) state = res.data[codeFieldName]
    let data = res.data
    if (dataFieldName && res.data[dataFieldName] !== undefined) data = res.data[dataFieldName]
    // const state = (codeFieldName && res.data[codeFieldName] !== undefined) || res.status
    // const data = (dataFieldName && res.data[dataFieldName] !== undefined) || res.data
    if (state >= 200 && state <= 299) {
      if (res.data instanceof Blob) {
        let contentType = res.headers['content-type']
        if (
          contentType == 'application/octet-stream' ||
          contentType == 'application/pdf' ||
          contentType == 'application/json'
        ) {
          const contentDisposition = res.headers['content-disposition']
          const filename = decodeURIComponent(
            contentDisposition
              .split(';')[2]
              .split('=')[1]
              .split("'")[2]
          )
          return blobToJson(res.data, filename)
        } else if (contentType.indexOf('application/json') == 0) {
          var reader = new FileReader()
          reader.addEventListener('loadend', function (e) {
            // 输出字符串 {hello: world}
            let err = JSON.parse(e.target.result)
            let state = err.Code
            if (state == 401) {
              return reftoken(response, err.Error)
            } else if (state >= 400 && state <= 499) {
              error400(err.Error)
            } else if (state >= 500 && state <= 599) {
              error500(err.Error)
            }
          })
          reader.readAsText(res.data)
        }
      }
      return res.data
    } else if (state == 401) {
      return reftoken(response, error)
    } else if (state >= 400 && state <= 499) {
      error400(error)
      // return Promise.reject(error)
    } else if (state >= 500 && state <= 599) {
      error500(error)
      return Promise.reject(error)
    }
  },
  error => {
    if (!error.response) {
      error500({ Message: '连接中断请稍后再试' })
    }
    const errMsgs = error.response.data.Error || error.response.data
    if (error.response.status === 401) {
      return reftoken(error, errMsgs)
    } else if (error.response.status >= 400 && error.response.status <= 499) {
      error400(errMsgs)
      return Promise.reject(errMsgs)
    } else {
      error500(errMsgs)
      return Promise.reject(errMsgs)
    }
  }
)
let error400 = function (error) {
  error.Message &&
    Message({
      message: error.Message,
      type: 'error',
      duration: 2 * 1000
    })
}
let error500 = function (error) {
  Message({
    message: error.Message,
    type: 'error',
    duration: 2 * 1000
  })
}

let reftoken = function (error, errMsgs) {
  const config = error.config
  if (!isRefreshing) {
    isRefreshing = true
    return store
      .dispatch('user/refreshToken', store.getters.token[tokenRefField])
      .then(() => {
        isRefreshing = false
        retryRequests.forEach(cb => cb())
        retryRequests = []
        return service(config)
      })
      .catch(e => {
        console.log(e)
        isRefreshing = false
      })
      .finally(() => {
        isRefreshing = false
      })
  } else {
    if (config.url.indexOf('api/Account/refreshtoken') > -1) {
      errMsgs.Message &&
        Message({
          message: errMsgs.Message,
          type: 'error', // 'success' | 'warning' | 'info' | 'error'
          duration: 1.2 * 1000,
          onClose: function () {
            store.dispatch('user/resetToken')
            location.href = '/login?redirect=' + location.pathname
          }
        })
      return Promise.reject(errMsgs)
    } else {
      return new Promise(resolve => {
        retryRequests.push(() => {
          resolve(service(config))
        })
      })
    }
  }
}
export const blobToJson = (blob, fileName) => {
  return new Promise((resolve, reject) => {
    try {
      const link = document.createElement('a')
      link.download = decodeURIComponent(fileName)
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      document.body.appendChild(link)
      link.click()
      URL.revokeObjectURL(link.href)
      document.body.removeChild(link)
      resolve({ Code: 200, Data: '下载成功' })
    } catch (e) {
      reject({ Code: 500, Data: e })
    }
  })
}
export default service