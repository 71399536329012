import request from '@/utils/request'


// 获取token 登陆
export function token(data) {
  return request({
    url: '/api/Account/Token',
    method: 'post',
    data,
  })
}

// 获取用户信息
export function getInfo(data) {
  return request({
    url: '/api/Account/GetInfo',
    method: 'post',
    data,
  })
}

export function refreshToken(data) {
  return request({
    url: '/api/account/refreshtoken',
    method: 'post',
    isAnonymous: true,
    data
  })
}