// import Cookies from 'js-cookie'
import defaultSettings from '@/utils/settings'

const { tokenKey, userKey } = defaultSettings

export function getToken() {
  let tokenStr = localStorage.getItem(tokenKey) // || Cookies.get(tokenKey)
  if (tokenStr == null || tokenStr == undefined || tokenStr == '' || tokenStr == 'null' || tokenStr == 'undefined')
    return null
  return tokenStr && JSON.parse(tokenStr)
}

export function setToken(token) {
  // return Cookies.set(tokenKey, (localStorage[tokenKey] = JSON.stringify(token)))
  localStorage.setItem(tokenKey, JSON.stringify(token))
}

export function removeToken() {
  localStorage.clear()
  return null // && Cookies.remove(tokenKey)
}

export function getUser() {
  let userStr = localStorage.getItem(userKey) // || Cookies.get(userKey)
  if (userStr == null || userStr == undefined || userStr == '' || userStr == 'null' || userStr == 'undefined')
    return null
  return userStr && JSON.parse(userStr)
}

export function setUser(user) {
  // return Cookies.set(userKey, (localStorage[userKey] = JSON.stringify(user)))
  localStorage.setItem(userKey, JSON.stringify(user))
  // localStorage[userKey] = JSON.stringify(user)
}

export function removeUser() {
  return delete localStorage[userKey] // && Cookies.remove(userKey)
}

export function getTopMenu() {
  let topMenuStr = localStorage.getItem('topMenu')
  if (
    topMenuStr == null ||
    topMenuStr == undefined ||
    topMenuStr == '' ||
    topMenuStr == 'null' ||
    topMenuStr == 'undefined'
  )
    return ''
  return topMenuStr
}

export function setTopMenu(topMenu) {
  localStorage.setItem('topMenu', topMenu)
}
